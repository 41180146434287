import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactComponent } from './contact/contact.component';
import { Page404Component } from "./page404/page404.component";

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'projects', loadChildren: './projects/projects.module#ProjectsModule' },
  { path: 'contact', component: ContactComponent },
  // { path: '**', component: Page404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { 
    scrollPositionRestoration: 'enabled', //coloca la posicion top en 0 al entrar a una ruta
     //enableTracing: true, //Solo para propositos de debuggin
     preloadingStrategy: PreloadAllModules //Carga los demás módulos en segundo plano luego de que el primer módulo cargara
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
